/**
 * @flow
 */

import Container from 'components/Container';
import ExternalFooterLink from './ExternalFooterLink';
import FooterLink from './FooterLink';
import FooterNav from './FooterNav';
import MetaTitle from 'components/MetaTitle';
import React from 'react';
import { colors, media } from 'theme';
import {
  /*sectionListCommunity, */ sectionListLejer,
  sectionListUdlejer
} from 'utils/sectionList';

// import ossLogoPng from 'images/oss_logo.png';

const Footer = ({
  layoutHasSidebar = false
}: {
  layoutHasSidebar: boolean
}) => (
  <footer
    css={{
      backgroundColor: colors.darker,
      color: colors.white,
      paddingTop: 10,
      paddingBottom: 50,

      [media.size('sidebarFixed')]: {
        paddingTop: 40
      }
    }}>
    <Container>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',

          [media.between('small', 'medium')]: {
            paddingRight: layoutHasSidebar ? 240 : null
          },

          [media.between('large', 'largerSidebar')]: {
            paddingRight: layoutHasSidebar ? 280 : null
          },
          [media.between('largerSidebar', 'sidebarFixed', true)]: {
            paddingRight: layoutHasSidebar ? 380 : null
          }
        }}>
        <div
          css={{
            flexWrap: 'wrap',
            display: 'flex',
            width: '100%'

            // [media.lessThan('large')]: {
            //   width: '100%'
            // },
            // [media.greaterThan('xlarge')]: {
            //   width: 'calc(100% / 3 * 2)',
            //   paddingLeft: 40
            // }
          }}>
          <FooterNav layoutHasSidebar={layoutHasSidebar}>
            <MetaTitle onDark={true}>For Lejer</MetaTitle>
            {sectionListLejer.map(section => {
              const defaultItem = section.items ? section.items[0] : section;
              return (
                <FooterLink to={`/lejer/${defaultItem.id}`} key={section.title}>
                  {section.title}
                </FooterLink>
              );
            })}
          </FooterNav>
          <FooterNav layoutHasSidebar={layoutHasSidebar}>
            <MetaTitle onDark={true}>For Udlejer</MetaTitle>
            {sectionListUdlejer.map(section => {
              const defaultItem = section.items ? section.items[0] : section;
              return (
                <FooterLink
                  to={`/udlejer/${defaultItem.id}`}
                  key={section.title}>
                  {section.title}
                </FooterLink>
              );
            })}
          </FooterNav>
          <FooterNav layoutHasSidebar={layoutHasSidebar}>
            <MetaTitle onDark={true}>Info</MetaTitle>
            <FooterLink to="/info/links">Nyttige links</FooterLink>
            <FooterLink to="/info/om">Om Lejeloven.dk</FooterLink>
            <FooterLink to="/info/kontakt">Kontakt</FooterLink>
            <FooterLink to="/info/ansvarsfraskrivelse">
              Ansvarsfraskrivelse
            </FooterLink>
            <FooterLink to="/en">English</FooterLink>
            <br />
            <br />
            <MetaTitle onDark={true}>Links</MetaTitle>
            <ExternalFooterLink
              href="https://www.akutbolig.dk/type/lejlighed"
              target="_blank"
              rel="noopener">
              Lejligheder til leje
            </ExternalFooterLink>
            <ExternalFooterLink
              href="https://xn--indskudsln-95a.dk/"
              target="_blank"
              rel="noopener">
              Indskudslån
            </ExternalFooterLink>
            <ExternalFooterLink
              href="https://udlejer.dk/"
              target="_blank"
              rel="noopener">
              Udlej bolig
            </ExternalFooterLink>
          </FooterNav>
          {/* <FooterNav layoutHasSidebar={layoutHasSidebar}>
            <MetaTitle onDark={true}>Info</MetaTitle>
            <FooterLink to="/info/om">Om Lejeloven.dk</FooterLink>
            <FooterLink to="/info/ansvarsfraskrivelse">
              Ansvarsfraskrivelse
            </FooterLink>
            <FooterLink to="/info/kontakt">Kontakt</FooterLink>
          </FooterNav> */}
        </div>
        <section
          css={{
            textAlign: 'center',
            width: '100%',
            paddingTop: 40,
            display: 'block !important' // Override 'Installation' <style> specifics

            // [media.greaterThan('xlarge')]: {
            //   width: 'calc(100% / 3)',
            //   order: -1
            // },
            // [media.greaterThan('large')]: {
            //   order: -1,
            //   width: layoutHasSidebar ? null : 'calc(100% / 3)'
            // },
            // [media.lessThan('large')]: {
            //   textAlign: 'center',
            //   width: '100%',
            //   paddingTop: 40
            // }
          }}>
          {/* <a
            href="https://code.facebook.com/projects/"
            target="_blank"
            rel="noopener">
            <img
              alt="Facebook Open Source"
              css={{
                maxWidth: 160,
                height: 'auto',
              }}
              src={ossLogoPng}
            />
          </a> */}
          <p
            css={{
              color: colors.subtleOnDark,
              paddingTop: 15
            }}>
            Copyright © {new Date().getFullYear()} Lejeloven.dk
          </p>
        </section>
      </div>
    </Container>
  </footer>
);

export default Footer;
