/**
 * @flow
 */

import Helmet from 'react-helmet';
import React from 'react';

const defaultDescription =
  'Lejeloven, dansk lov om leje - så den er til at forstå';

type Props = {
  title: string,
  ogDescription?: string,
  ogUrl?: string
};

const TitleAndMetaTags = ({ title, ogDescription, ogUrl }: Props) => {
  return (
    <Helmet title={title}>
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      {ogUrl && <link rel="canonical" href={ogUrl} />}
      {ogUrl && <meta property="og:url" content={ogUrl} />}
      {/* <meta property="og:image" content="/logo.png" /> */}
      <meta
        property="og:description"
        content={ogDescription || defaultDescription}
      />
      {/* <meta property="fb:app_id" content="" /> */}
    </Helmet>
  );
};

export default TitleAndMetaTags;
