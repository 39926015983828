/**
 * @flow
 */

import React from 'react';
import { colors /*, fonts*/, media } from 'theme';
import Menu from 'components/Menu';
import MetaTitle from 'components/MetaTitle';
import MenuLink from './MenuLink';

import Container from 'components/Container';
import Flex from 'components/Flex';
import ButtonLink from 'components/ButtonLink';
import TenantSvg from 'components/svg/Tenant';
import DwellingSvg from 'components/svg/Dwelling';

import { sectionListLejer, sectionListUdlejer } from 'utils/sectionList';

// // $FlowExpectedError
// import navLejer from '../../../content/lejer/nav.yml';
// // $FlowExpectedError
// import navUdlejer from '../../../content/udlejer/nav.yml';
// $FlowExpectedError
import navFortolkning from '../../../content/fortolkning/nav.yml';

type Props = {
  location: Location,
  open: boolean,
  onRequestClose?: Function
};

type State$selected = 'lejer' | 'udlejer';

type State = {
  selected: State$selected,
  sections: Array<{
    title: string,
    directory: string,
    singlePage?: boolean,
    items: Array<{ id: string, title: string }>
  }>,
  columns: 2 | 3,
  mq: mixed
};

class HeaderMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const mq = media.match(media.between('small', 'medium'));
    const selected = /^\/udlejer\//.test(props.location.pathname)
      ? 'udlejer'
      : 'lejer';
    this.state = {
      ...this._onSelect(selected),
      columns: mq && mq.matches ? 2 : 3,
      mq: mq
    };

    if (mq) {
      mq.addListener(this._mediaChange);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.open && !this.props.open) {
      this._onOpen(nextProps);
    }
  }

  _onOpen(props: Props = this.props) {
    if (/^\/(?:ud)?lejer\//.test(props.location.pathname)) {
      const match = props.location.pathname.match(/^\/((?:ud)?lejer)\//);
      const selected = match[1];

      this.setState(this._onSelect(selected));
    }
  }

  _onSelect(selected: State$selected) {
    return {
      selected,
      sections: [].concat(
        selected === 'udlejer' ? sectionListUdlejer : sectionListLejer,
        navFortolkning
      )
    };
  }

  // componentDidMount() {
  //   console.log('componentDidMount', this.state.mq);
  //   // // media query event handler
  //   // if (matchMedia) {
  //   //   const mq = window.matchMedia('(min-width: 500px)');
  //   //   mq.addListener(WidthChange);
  //   //   WidthChange(mq);
  //   // }
  //   //
  //   // // media query change
  //   // function WidthChange(mq) {
  //   //   if (mq.matches) {
  //   //     // window width is at least 500px
  //   //   } else {
  //   //     // window width is less than 500px
  //   //   }
  //   // }
  // }

  _mediaChange = mq => {
    this.setState({ columns: mq.matches ? 2 : 3 });
  };

  _requestClose = () => {
    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    }
  };

  _changeSection = (section: State$selected) => {
    this.setState(this._onSelect(section));
  };

  render() {
    // console.log({
    //   sectionListLejer,
    //   sectionListUdlejer,
    //   navLejer,
    //   navUdlejer,
    //   navFortolkning
    // });

    const { sections, columns } = this.state;

    const count = sections.reduce((acc, section) => {
      return acc + section.items.length + 1;
    }, 0);
    const perColumn = Math.ceil(count / columns);

    let pointer = 0;
    const navCols = sections.reduce(
      (cols, section) => {
        const len = section.items.length + 1;
        const distance = perColumn - pointer;
        if (pointer > perColumn || distance < Math.abs(distance - len)) {
          pointer = 0;
          cols.push([]);
        }
        pointer += len;
        cols[cols.length - 1].push(section);
        return cols;
      },
      [[]]
    );

    return (
      <Menu {...this.props}>
        <Container>
          <div
            css={{
              display: 'flex',
              marginTop: 20,
              borderBottom: `${colors.brand} 2px solid`
            }}>
            <SectionTab
              Icon={TenantSvg}
              onClick={() => this._changeSection('lejer')}
              selected={this.state.selected === 'lejer'}>
              for <strong>lejere</strong>
            </SectionTab>
            <SectionTab
              Icon={DwellingSvg}
              onClick={() => this._changeSection('udlejer')}
              selected={this.state.selected === 'udlejer'}>
              for <strong>udlejere</strong>
            </SectionTab>
          </div>
          <div
            css={{
              display: 'flex',
              paddingBottom: 20,
              // paddingLeft: 20,
              // paddingRight: 20,

              [media.greaterThan('largerSidebar')]: {
                paddingLeft: 40,
                paddingRight: 40
              },
              [media.lessThan('small')]: {
                display: 'block'
              }
            }}>
            {navCols.map((sections, idx) => {
              return (
                <div
                  key={idx}
                  css={{
                    flex: 1,
                    paddingLeft: 10,
                    paddingRight: 10
                  }}>
                  {sections.map((section, idx) => {
                    return (
                      <div
                        key={idx}
                        css={{
                          marginTop: 20
                          // fontSize: '0.8em'
                        }}>
                        <MetaTitle
                          cssProps={{
                            [media.greaterThan('small')]: {
                              color: colors.subtle
                              // color: isActive ? colors.text : colors.subtle,
                              // ':hover': {
                              //   color: colors.text
                              // }
                            }
                          }}>
                          {section.title}
                          {/* <ChevronSvg
                          cssProps={{
                            marginLeft: 7,
                            transform: `rotateX(${'0deg'})`,
                            // transform: `rotateX(${isActive ? '180deg' : '0deg'})`,
                            transition: 'transform 0.2s ease',

                            [media.lessThan('small')]: {
                              display: 'none'
                            }
                          }}
                        /> */}
                        </MetaTitle>

                        {/* <h4
                        css={{
                          // fontSize: '0.8em',
                          fontWeight: 500,
                          textTransform: 'uppercase',
                          textDecoration: 'underline'
                        }}>
                        {section.title}
                      </h4> */}
                        <ul>
                          {section.items.map(item => {
                            return (
                              <li key={item.id}>
                                <MenuLink
                                  onClick={this._requestClose}
                                  to={`/${section.directory}${
                                    section.singlePage === true ? '#' : '/'
                                  }${item.id}`}>
                                  {item.title}
                                </MenuLink>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </Container>
      </Menu>
    );
  }
}

export default HeaderMenu;

const SectionTab = ({ selected, Icon, children, ...props }) => {
  return (
    <div
      css={[
        {
          flex: 1,
          textAlign: 'center',
          cursor: 'pointer',
          color: selected ? colors.brand : 'inherit',
          borderBottom: selected ? `${colors.brand} 4px solid` : ''
        },
        selected
          ? null
          : {
              ':hover': {
                color: colors.subtle
              }
            }
      ]}
      {...props}>
      <Icon
        size={48}
        cssProps={{
          display: 'inline-block',
          margin: '0 auto 10px',
          verticalAlign: 'middle'
        }}
      />
      {children}
    </div>
  );
};
