/**
 * @flow
 */

// import Container from 'components/Container';
import React, { Component } from 'react';
import type { Node } from 'react';
import Flex from 'components/Flex';
// import Sidebar from 'components/Sidebar';
import { colors, media } from 'theme';
// import ChevronSvg from 'components/svg/Chevron';
// import ArrowUpSvg from 'components/svg/ArrowUp';
import FloatingButton from 'components/Menu/FloatingButton';

type State = {};

type Props = {
  children: Node,
  open: boolean,
  onRequestClose?: Function
  // enableScrollSync?: boolean,
  // createLink: Function, // TODO: Add better flow type once we Flow-type createLink
  // defaultActiveSection: string,
  // location: Location,
  // sectionList: Array<Object> // TODO: Add better flow type once we have the Section component
};

class Menu extends Component<Props, State> {
  // constructor(props: Props) {
  //   super(props);
  //
  //   this.state = {
  //     open: false
  //   };
  // }

  _requestClose = () => {
    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    }
  };

  render() {
    const { open } = this.props;
    const smallScreenSidebarStyles = {
      top: 40,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'fixed',
      backgroundColor: colors.white,
      zIndex: 2,
      // height: '100vh',
      height: 'calc(100vh - 40px)',
      overflowY: 'auto',
      WebkitOverflowScrolling: 'touch',
      pointerEvents: open ? 'auto' : 'none'
    };

    const smallScreenBottomBarStyles = {
      display: 'inline-block'
    };

    const iconOffset = open ? 3 : -12;
    const menuOpacity = open ? 1 : 0;
    const menuOffset = open ? 0 : 40;

    // TODO: role and aria props for 'close' button?
    return (
      <div>
        <div
          style={{
            opacity: menuOpacity,
            transition: 'opacity 0.5s ease'
          }}
          css={{
            background: 'rgba(255, 255, 255, 0.9)',
            ...smallScreenSidebarStyles,
            [media.lessThan('small')]: smallScreenSidebarStyles,
            //
            // [media.greaterThan('medium')]: {
            //   marginRight: -999,
            //   paddingRight: 999,
            //   backgroundColor: '#f7f7f7'
            // },
            //
            [media.between('medium', 'sidebarFixed', true)]: {
              position: 'fixed',
              zIndex: 2,
              height: '100%'
            },

            [media.greaterThan('small')]: {
              position: 'fixed',
              zIndex: 2,
              top: 60,
              height: 'calc(100vh - 60px)',
              overflowY: 'auto',
              WebkitOverflowScrolling: 'touch',
              marginRight: -999,
              paddingRight: 999,
              backgroundColor: '#f7f7f7'
              // opacity: '1 !important'
            },

            [media.size('small')]: {
              top: 50,
              height: 'calc(100vh - 50px)'
            },

            [media.between('medium', 'large')]: {
              top: 50,
              height: 'calc(100vh - 50px)'
            }
            //
            // [media.greaterThan('sidebarFixed')]: {
            //   borderLeft: '1px solid #ececec'
            // }
          }}>
          <div
            style={{
              transform: `translate(${menuOffset}px, 0px)`,
              transition: 'transform 0.5s ease'
            }}
            css={
              {
                // marginTop: 60,
                //
                // [media.size('xsmall')]: {
                //   marginTop: 40
                // },
                //
                // [media.between('small', 'medium')]: {
                //   marginTop: 0
                // },
                //
                // [media.between('medium', 'large')]: {
                //   marginTop: 50
                // },
                // [media.greaterThan('small')]: {
                //   transform: 'none !important'
                // }
              }
            }>
            <Flex
              type="nav"
              direction="column"
              halign="stretch"
              css={{
                color: 'black',

                width: '100%',
                position: 'relative',

                // paddingLeft: 20,
                // [media.greaterThan('largerSidebar')]: {
                //   paddingLeft: 40
                // },

                [media.lessThan('small')]: {
                  paddingBottom: 100
                }
              }}>
              {this.props.children}
            </Flex>
            {/* <Sidebar closeParentMenu={this._closeNavMenu} {...this.props} /> */}
          </div>
        </div>
        {/* {open ? (
          <FloatingButton open={open} onClick={this._requestClose} />
        ) : null} */}
      </div>
    );
  }
}

export default Menu;
