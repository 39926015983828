/**
 * @flow
 */

import Link from 'gatsby-link';
import React from 'react';
import { colors } from 'theme';

import type { Node } from 'react';

type Props = {
  children: Node,
  target?: string,
  to: string
};

const MenuLink = ({ children, target, to, onClick }: Props) => (
  <Link
    css={{
      lineHeight: 2,
      ':hover': {
        color: colors.brand
      }
    }}
    to={to}
    target={target}
    onClick={onClick}>
    {children}
  </Link>
);

export default MenuLink;
