/**
 * @flow
 */

import Container from 'components/Container';
import React, { Component } from 'react';
// import Sidebar from 'components/Sidebar';
import { colors, media } from 'theme';
// import ChevronSvg from 'components/svg/Chevron';
import ArrowUpSvg from 'components/svg/ArrowUp';

// type State = {
//   open: boolean
// };

type Props = {
  open: boolean,
  onClick: Function
  // enableScrollSync?: boolean
  // createLink: Function, // TODO: Add better flow type once we Flow-type createLink
  // defaultActiveSection: string,
  // location: Location,
  // sectionList: Array<Object> // TODO: Add better flow type once we have the Section component
};

class FloatingButton extends Component<Props /*, State*/> {
  // constructor(props: Props) {
  //   super(props);
  //
  //   this.state = {
  //     open: false
  //   };
  // }

  // _openNavMenu = () => {
  //   this.setState({ open: !this.state.open });
  // };
  //
  // _closeNavMenu = () => {
  //   this.setState({ open: false });
  // };

  _onClick = () => {
    this.props.onClick();
  };

  render() {
    const { open } = this.props;
    // const smallScreenSidebarStyles = {
    //   top: 0,
    //   left: 0,
    //   bottom: 0,
    //   right: 0,
    //   position: 'fixed',
    //   backgroundColor: colors.white,
    //   zIndex: 2,
    //   height: '100vh',
    //   overflowY: 'auto',
    //   WebkitOverflowScrolling: 'touch',
    //   pointerEvents: open ? 'auto' : 'none'
    // };

    const smallScreenBottomBarStyles = {
      display: 'inline-block'
    };

    const iconOffset = open ? 3 : -12;
    // const menuOpacity = open ? 1 : 0;
    // const menuOffset = open ? 0 : 40;

    // TODO: role and aria props for 'close' button?
    return (
      <div
        css={{
          backgroundColor: colors.darker,
          bottom: 30, // iOS Safari's inert "bottom 44px"
          color: colors.brand,
          display: 'none', // gets overriden at small screen sizes
          cursor: 'pointer',
          position: 'fixed',
          right: 20,
          zIndex: 3,
          borderRadius: '50%',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)',
          [media.lessThan('small')]: smallScreenBottomBarStyles
        }}
        onClick={this._onClick}
        role="button"
        tabIndex={0}>
        <Container>
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: 60,
              [media.between('medium', 'large')]: {
                height: 50
              },
              [media.lessThan('small')]: {
                height: 60,
                overflow: 'hidden',
                alignItems: 'flex-start'
              }
            }}>
            <div
              css={{
                width: 20,
                height: 20,
                alignSelf: 'center',
                display: 'flex',
                flexDirection: 'column',
                color: colors.brand
              }}>
              <ArrowUpSvg
                size={36}
                cssProps={{
                  position: 'absolute',
                  top: 12,
                  left: 0,
                  transform: `translate(12px, ${iconOffset}px) rotate(180deg)`,
                  transition: 'transform 0.2s ease'
                }}
              />
              <ArrowUpSvg
                size={36}
                cssProps={{
                  position: 'absolute',
                  top: 12,
                  left: 0,
                  transform: `translate(12px, ${0 - iconOffset}px)`,
                  transition: 'transform 0.2s ease'
                }}
              />
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default FloatingButton;
