/**
 * @flow
 */

import React from 'react';

type Props = {
  size: number,
  cssProps: Object
};

const ArrowUpSvg = ({ size = 24, cssProps = {} }: Props) => (
  <svg
    css={cssProps}
    viewBox="0 0 24 24"
    version="1.1"
    width={size}
    height={size}
    fill="currentColor">
    <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" />
    <path d="M0-.75h24v24H0z" fill="none" />
  </svg>
);

export default ArrowUpSvg;
