/**
 * @flow
 */

import Container from 'components/Container';
import HeaderLink from './HeaderLink';
import Link from 'gatsby-link';
import React from 'react';
import { colors /*, fonts*/, media } from 'theme';
// import ExternalLinkSvg from 'components/svg/Launch';
// import Search from './Search';

import logoSvg from 'icons/logo.svg';

import Menu from './Menu';
import MenuIcon from 'components/svg/Menu';
import CloseIcon from 'components/svg/Close';
// import Logo from 'components/svg/Logo';

type Props = {
  location: Location
};

type State = {
  menuOpen: boolean
};

class Header extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { menuOpen: false };
  }

  _closeNavMenu = () => {
    this.setState({ menuOpen: false });
  };

  render() {
    const { location } = this.props;
    return (
      <header
        css={{
          backgroundColor: colors.darker,
          color: colors.white,
          position: 'fixed',
          zIndex: 1,
          width: '100%',
          top: 0,
          left: 0
        }}>
        <Container>
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: 60,
              [media.between('small', 'large')]: {
                height: 50
              },
              [media.lessThan('small')]: {
                height: 40
              }
            }}>
            <Link
              css={{
                display: 'flex',
                marginRight: 10,
                height: '100%',
                alignItems: 'center',
                color: colors.brand,

                ':focus': {
                  outline: 0,
                  color: colors.white
                },

                [media.greaterThan('small')]: {
                  width: 'calc(100% / 6)'
                },
                [media.lessThan('small')]: {
                  flex: '0 0 auto'
                }
              }}
              to="/">
              <img src={logoSvg} alt="" height="20" />
              <span
                css={{
                  color: 'inherit',
                  marginLeft: 10,
                  fontWeight: 700,
                  fontSize: 20,
                  lineHeight: '20px',
                  [media.lessThan('large')]: {
                    fontSize: 16
                    // marginTop: 1,
                  },
                  [media.lessThan('small')]: {
                    // Visually hidden
                    position: 'absolute',
                    overflow: 'hidden',
                    clip: 'rect(0 0 0 0)',
                    height: 1,
                    width: 1,
                    margin: -1,
                    padding: 0,
                    border: 0
                  }
                }}>
                Lejeloven
              </span>
            </Link>

            <nav
              css={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: '1',
                alignItems: 'stretch',
                overflowX: 'auto',
                overflowY: 'hidden',
                WebkitOverflowScrolling: 'touch',
                height: '100%',
                width: '60%',

                [media.size('xsmall')]: {
                  flexGrow: '1',
                  width: 'auto'
                },
                [media.greaterThan('xlarge')]: {
                  width: null
                },
                [media.lessThan('small')]: {
                  paddingLeft: 10,
                  maskImage:
                    'linear-gradient(to right, transparent, black 20px, black 90%, transparent)'
                }
              }}>
              <HeaderLink
                isActive={location.pathname.includes('/fortolkning')}
                title="Lejeloven"
                to="/fortolkning"
                onClick={this._closeNavMenu}
              />
              <HeaderLink
                isActive={location.pathname.includes('/lejer/')}
                title="Lejer"
                to="/lejer/"
                onClick={this._closeNavMenu}
              />
              <HeaderLink
                isActive={location.pathname.includes('/udlejer/')}
                title="Udlejer"
                to="/udlejer/"
                onClick={this._closeNavMenu}
              />
            </nav>

            <span
              css={{
                cursor: 'pointer',
                display: 'flex',
                marginLeft: 10,
                height: '100%',
                alignItems: 'center',
                justifyContent: 'flex-end',
                color: this.state.menuOpen ? colors.brandLight : colors.white,

                ':focus': {
                  outline: 0,
                  color: colors.white
                },

                [media.greaterThan('small')]: {
                  width: 'calc(100% / 6)'
                },
                [media.lessThan('small')]: {
                  flex: '0 0 auto'
                }
              }}
              onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}>
              <span
                css={{
                  color: 'inherit',
                  marginRight: 10,
                  // fontWeight: 700,
                  // textTransform: 'uppercase',
                  // fontSize: 20,
                  // lineHeight: '20px',
                  // [media.lessThan('large')]: {
                  //   fontSize: 16
                  //   // marginTop: 1,
                  // },
                  [media.greaterThan('xlarge')]: {
                    fontSize: 18
                  },
                  [media.lessThan('small')]: {
                    // Visually hidden
                    position: 'absolute',
                    overflow: 'hidden',
                    clip: 'rect(0 0 0 0)',
                    height: 1,
                    width: 1,
                    margin: -1,
                    padding: 0,
                    border: 0
                  }
                }}>
                Menu
              </span>
              {!this.state.menuOpen ? (
                <MenuIcon size="20" />
              ) : (
                <CloseIcon size="20" />
              )}
            </span>
            <Menu
              location={location}
              open={this.state.menuOpen}
              onRequestClose={() => this.setState({ menuOpen: false })}
            />
          </div>
        </Container>
      </header>
    );
  }
}

export default Header;
