/**
 * @flow
 */

// Polyfills for IE
// import 'array-from';
// import 'string.prototype.includes';
// import 'string.prototype.repeat';

import * as React from 'react';
import Flex from 'components/Flex';
import Footer from 'components/LayoutFooter';
import Header from 'components/LayoutHeader';
import { media, layoutHasSidebar } from 'theme';
// import CookieDisclaimer from './CookieDisclaimer';

// Import global styles
// import '../prism-styles';
// import 'glamor/reset';
// import 'css/reset.css';

type Props = {
  children: React.Node,
  location: Location
};

class Template extends React.Component<Props> {
  render() {
    const { children, location } = this.props;

    return (
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: 'calc(100vh - 40px)'
        }}>
        <Header location={location} />
        <Flex
          direction="column"
          shrink={0}
          grow={1}
          valign="stretch"
          css={{
            flex: '1 0 auto',
            marginTop: 60,
            [media.between('medium', 'large')]: {
              marginTop: 50
            },
            [media.lessThan('medium')]: {
              marginTop: 40
            }
          }}>
          {children}
        </Flex>
        <Footer layoutHasSidebar={layoutHasSidebar(location)} />
        {/* <CookieDisclaimer /> */}
      </div>
    );
  }
}

export default Template;
