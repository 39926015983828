/**
 * @flow
 */

import React from 'react';

type Props = {
  size: number,
  cssProps: Object
};

const DwellingSvg = ({ size = 24, cssProps = {} }: Props) => (
  <svg
    css={cssProps}
    viewBox="0 0 24 24"
    version="1.1"
    width={size}
    height={size}
    fill="currentColor">
    <path d="M15 11V5l-3-3-3 3v2H3v14h18V11h-6zm-8 8H5v-2h2v2zm0-4H5v-2h2v2zm0-4H5V9h2v2zm6 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2V9h2v2zm0-4h-2V5h2v2zm6 12h-2v-2h2v2zm0-4h-2v-2h2v2z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

export default DwellingSvg;
