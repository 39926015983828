/**
 * @flow
 */

import React from 'react';

type Props = {
  size: number,
  cssProps: Object
};

const MenuSvg = ({ size = 24, cssProps = {} }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    css={cssProps}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
  </svg>
);

export default MenuSvg;
