/**
 * @flow
 */

import React from 'react';
import { colors } from 'theme';

const MetaTitle = ({ children, title, cssProps = {}, onDark = false }) => (
  <div
    css={{
      color: onDark ? colors.subtleOnDark : colors.subtle,
      fontSize: 13,
      fontWeight: 700,
      lineHeight: 2.4,
      textTransform: 'uppercase',
      letterSpacing: '0.08em',
      whiteSpace: 'nowrap',
      ...cssProps
    }}>
    {children}
  </div>
);

export default MetaTitle;
