/**
 * @flow
 */

// $FlowExpectedError
import navLejer from '../../content/lejer/nav.yml';
// $FlowExpectedError
import navUdlejer from '../../content/udlejer/nav.yml';
// $FlowExpectedError
import navFortolkning from '../../content/fortolkning/nav.yml';
// $FlowExpectedError
import navEnglish from '../../content/en/nav.yml';

const sectionListLejer = navLejer.map(
  (item: Object): Object => ({
    ...item,
    directory: 'lejer'
  })
);

const sectionListUdlejer = navUdlejer.map(
  (item: Object): Object => ({
    ...item,
    directory: 'udlejer'
  })
);

export {
  sectionListLejer,
  sectionListUdlejer,
  navFortolkning as sectionListFortolkning,
  navEnglish as sectionListEnglish
};
